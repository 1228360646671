import React, {Component} from 'react';
import FeatureLeftItemServicios from './FeatureLeftItemServicios';
import FeatureRightItem from './FeatureRightItem';

class TopFeatureServicios extends Component{
    render(){
        return(
            <section className="features-area" id="features">
                <FeatureLeftItemServicios
                    Title="Impulsamos a primeras marcas digitales y las preparamos para escalar."
                    Details="Trabajamos con microempresas emergentes y en expansión para definir su identidad visual de marca, con la intención de fortalecer la recaudación de capital y la construcción de comunidades en el proceso."
                    BtnText="Esto me interesa"
                    Url="servicios"
                    Image="pad-img1.png"
                />
                <FeatureRightItem 
                    Title="Transformamos sitios web en activos integrales para la construcción de negocios."
                    Details="Ya sea que estés buscando construir una nueva experiencia digital desde cero o cambiar la que ya tienes, diseñamos y trabajamos soluciones de calidad para satisfacer las necesidades de cualquier equipo."
                    BtnText="Esto me interesa"
                    Url="servicios"
                    Image="pad-img2.png" 
                />
            </section>
        )
    }
}
export default TopFeatureServicios;