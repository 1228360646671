import React, {Component} from 'react';
import CenterTitle from './CenterTitle';
import WorkItem from './WorkItem';



class Works extends Component{
    render(){
        return(
            <section className={"how-work-area " + this.props.AddClass}>
                <div className="container">
                    <CenterTitle Title="¿Cómo trabajamos?" Subtitle={<React.Fragment>Ya sea que estés iniciando tu negocio, necesites reconectar con tus clientes o quieras escalar tu microempresa, nosotros trabajamos para que los productos web complementen satisfactoriamente la experiencia digital de tus clientes y generes nuevas oportunidades de crecimiento.<br/><br/>
               Después de contactar con nosotros, nos reunimos contigo para acordar los pasos necesarios para convertir tu idea en un proyecto web eficiente, atractivo y totalmente funcional.</React.Fragment>}/>
                    <div className="row">
                        <WorkItem Class=" wow fadeIn" delay="0.5s"
                            Icon="lnr lnr-magnifier"
                            Title="Investigamos"
                            Details="Por medio de la Investigación construimos un DX."
                        />
                        
                        <WorkItem Class=" wow fadeIn" delay="1s"
                            Icon="lnr lnr-picture"
                            Title="Diseñamos"
                            Details="Elaboramos una propuesta de diseño y desarrollo según lo detectado en el DX."
                        />
                        <WorkItem Class=" wow fadeIn" delay="1.3s"
                            Icon="lnr lnr-rocket"
                            Title="Probamos y ejecutamos 🙂"
                            Details="Corroboramos la producción del diseño y la implementación para finalmente llevarte al mundo digital."
                        />
                    </div>
                </div>
            </section>
        )
    }
}
export default Works;