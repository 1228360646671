import React, { Component } from "react";

class TestimonialsItem extends Component {
  render() {
    var {
      Image,
      Details,
      Icon1,
      Icon2,
      Icon3,
      Icon4,
      Icon5,
      IconUrl1,
      IconUrl2,
      IconUrl3,
      IconUrl4,
      IconUrl5,
      Name
    } = this.props;
    return (
      <div className="item">
        <img
          className="img-circle"
          src={require("../../assets/image/" + Image)}
          alt=""
        />
        <p>{Details}</p>
        <ul>
          <li>
            <a href={IconUrl1}>
              <i className={Icon1}></i>
            </a>
          </li>
          <li>
            <a href={IconUrl2}>
              <i className={Icon2}></i>
            </a>
          </li>
          <li>
            <a href={IconUrl3}>
              <i className={Icon3}></i>
            </a>
          </li>
          <li>
            <a href={IconUrl4}>
              <i className={Icon4}></i>
            </a>
          </li>
          <li>
            <a href={IconUrl5}>
              <i className={Icon5}></i>
            </a>
          </li>
        </ul>
        <h4>{Name}</h4>
      </div>
    );
  }
}
export default TestimonialsItem;
