import React, { Component } from "react";
import Slider from "react-slick";
import TestimonialsItem from "./TestimonialsItem";

class Testimonials extends Component {
  render() {
    function SampleNextArrow(props) {
      const { onClick } = props;
      return (
        <div className="prev_arrow arrow" onClick={onClick}>
          <i className="fa fa-angle-left"></i>
        </div>
      );
    }

    function SamplePrevArrow(props) {
      const { onClick } = props;
      return (
        <div className="next_arrow arrow" onClick={onClick}>
          <i className="fa fa-angle-right"></i>
        </div>
      );
    }
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      centerPadding: "100px",
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    var { BgImage, LeftTitle, CenterTitle, RightTitle } = this.props;
    return (
      <section className="user_review_area" id="testimonials">
        <span className="test_bg">
          <img src={require("../../assets/image/" + BgImage)} alt="" />
        </span>
        <div className="container">
          <div className="main_title">
            <h2>
              {LeftTitle} <samp>{CenterTitle}</samp> {RightTitle}
            </h2>
          </div>
          <div className="user_review_slider">
            <Slider {...settings} className="review_slider">
              <TestimonialsItem
                Image="user-1.png"
                Details="What a load of rubbish bits and bobs pear shaped owt to do with me bubble and squeak jolly good morish tinkety tonk old fruit, car boot my good sir buggered plastered cheeky David, haggle young delinquent say so I said bite your arm off easy peasy. Skive off it's all gone to pot buggered."
                Icon1="fa fa fa-star"
                IconUrl1="#"
                Icon2="fa fa fa-star"
                IconUrl2="#"
                Icon3="fa fa fa-star"
                IconUrl3="#"
                Icon4="fa fa fa-star"
                IconUrl4="#"
                Icon5="fa fa fa-star-half-o"
                IconUrl5="#"
                Name="Rocky Ahammed"
              />
              <TestimonialsItem
                Image="user-2.png"
                Details="What a load of rubbish bits and bobs pear shaped owt to do with me bubble and squeak jolly good morish tinkety tonk old fruit, car boot my good sir buggered plastered cheeky David, haggle young delinquent say so I said bite your arm off easy peasy. Skive off it's all gone to pot buggered."
                Icon1="fa fa fa-star"
                IconUrl1="#"
                Icon2="fa fa fa-star"
                IconUrl2="#"
                Icon3="fa fa fa-star"
                IconUrl3="#"
                Icon4="fa fa fa-star"
                IconUrl4="#"
                Icon5="fa fa fa-star-half-o"
                IconUrl5="#"
                Name="Manik Rony"
              />
              <TestimonialsItem
                Image="user-1.png"
                Details="What a load of rubbish bits and bobs pear shaped owt to do with me bubble and squeak jolly good morish tinkety tonk old fruit, car boot my good sir buggered plastered cheeky David, haggle young delinquent say so I said bite your arm off easy peasy. Skive off it's all gone to pot buggered."
                Icon1="fa fa fa-star"
                IconUrl1="#"
                Icon2="fa fa fa-star"
                IconUrl2="#"
                Icon3="fa fa fa-star"
                IconUrl3="#"
                Icon4="fa fa fa-star"
                IconUrl4="#"
                Icon5="fa fa fa-star-half-o"
                IconUrl5="#"
                Name="Obaidul Hqu"
              />
            </Slider>
          </div>
        </div>
      </section>
    );
  }
}
export default Testimonials;
