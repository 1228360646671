import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// All Css File Import
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/vendors/linear-icon/style.css";
import "./assets/vendors/ionicons/css/ionicons.min.css";
import "./assets/vendors/thimify-icon/themify-icons.css";
import "./assets/css/font-awesome.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/css/animate.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";

// All Js File Import
import "bootstrap/dist/js/bootstrap.min.js";
import "popper.js";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
