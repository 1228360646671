import React, {Component} from 'react';
import FeatureLeftItem from './FeatureLeftItem';
import FeatureRightItem from './FeatureRightItem';
import Servicios from "../Pages/Servicios";

class TopFeature extends Component{
    render(){
        return(
            <section className="features-area" id="features">
                <FeatureLeftItem 
                    Title="Impulsamos a primeras marcas digitales y las preparamos para escalar."
                    Details={<React.Fragment>Trabajamos con microempresas emergentes y en expansión para definir su <b>identidad visual de marca</b>, con la intención de fortalecer la recaudación de capital y la construcción de comunidades en el proceso.</React.Fragment>}
                    BtnText="Esto me interesa"
                    // Url="servicios"
                    Image="pad-img1.png" 
                />
                <FeatureRightItem 
                    Title="Transformamos sitios web en activos integrales para la construcción de negocios."
                    Details={<React.Fragment>Ya sea que estés buscando construir una nueva experiencia digital desde cero o cambiar la que ya tienes, <b>diseñamos e implementamos soluciones digitales de calidad</b> para satisfacer las necesidades.</React.Fragment>}
                    BtnText="Esto me interesa"
                    // Url="servicios"
                    Image="pad-img2.png" 
                />
            </section>
        )
    }
}
export default TopFeature;