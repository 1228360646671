import React, { Component } from "react";

import WaterWave from "react-water-wave";

import Typer from "./Typer";

class RippleBanner extends Component {
  render() {
    var { Details, BtnText, Url, Image } = this.props;
    return (
      <section className="hero-area hero-ripples ripples">
        <WaterWave
          strength={500}
          className="water_wave"
          style={{ width: "100%", height: "100%", backgroundSize: "cover" }}
          imageUrl={require("../../assets/image/baner/" + Image)}
        >
          {({ getRootProps }) => (
            <div className="container">
              <div className="hero-content">
                <div id="typed-strings">
                  <Typer
                    dataText={[
                      "Diseño & desarrollo web",
                      "Hosting & dominio / SEO",
                      "Branding",
                      "Nuestros servicios"
                    ]}
                  />
                </div>
                <h2 className="hero-title">
                  <span id="typed"></span>
                </h2>
                <p>{Details}</p>
                {/*<a*/}
                {/*  href={Url}*/}
                {/*  className="btn hero-btn wow fadeInLeft"*/}
                {/*  data-wow-delay="300ms"*/}
                {/*>*/}
                {/*  {BtnText}*/}
                {/*</a>*/}
              </div>
            </div>
          )}
        </WaterWave>
      </section>
    );
  }
}
export default RippleBanner;
