import React, {Component} from 'react';
import DashItem from './DashItem';

import Background from '../../assets/image/powerful-bg.jpg';

var Image = {
    background: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  };

class Dashboard extends Component{ 
    render(){
        var {LeftTitle, RightTitle} = this.props;
        return(
            <section className="powerful-features" style={ Image }>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 powerful-items power-item-l">
                            <h2 className="po-title">{LeftTitle}</h2>
                            <DashItem Class="wow fadeInUp" delay="200ms"
                                Icon="lnr lnr-laptop-phone"
                                Title="Nuevdjfljdskgjsdlkgjsdlk"
                                Details="Deseas que tu micro empresa cuente con una página web o te has dado cuenta de que tu negocio ha evolucionado y necesitas cambiar su plataforma o su principal vía de comunicación."
                            />
                            <DashItem Class="wow fadeInUp" delay="600ms"
                                Icon="lnr lnr-cog"
                                Title="Desarrollar cambios en tu identidad visual o necesitar estructurarla"
                                Details="Requieres de la construcción de la identidad visual de tu marca, ya sea porque estás comenzando o porque te has dado cuenta de que tu negocio ha evolucionado y necesitas cambiar los elementos visuales de tu plataforma o tu principal vía de comunicación."
                            />
                            <DashItem Class="wow fadeInUp" delay="900ms"
                                Icon="lnr lnr-cloud-download"
                                Title="Proveedor de hosting"
                                Details="Si aún no cuentas con un servicio de alojamiento o deseas cambiar de proveedor de alojamiento, te ofrecemos un servicio de alojamiento en AWS Cloud."
                            />
                        </div>
                        <div className="col-md-6 powerful-items power-item-r">
                            <h2 className="po-title">{RightTitle}</h2>
                            <DashItem Class="wow fadeInUp" delay="400ms"
                                Icon="lnr lnr-laptop"
                                Title="Sitios web y aplicaciones web"
                                Details="Diseñamos y construimos experiencias digitales sólidas, personalizadas y plataformas de comercio electrónico y aplicaciones web. Incluimos una combinación de investigación, diagramas de flujo de usuarios, diseño de UI / UX, prototipos funcionales, desarrollo de contenido, sistemas de diseño, diseño receptivo, arquitectura de información, análisis / datos / SEO y desarrollo de pila completa.."
                            />
                            <DashItem Class="wow fadeInUp" delay="800ms"
                                Icon="lnr lnr-database"
                                Title="Profesionalismo y tranquilidad 🙂"
                                Details="Deja de desmotivarte por no ver los resultados de la inversión de tu tiempo y recursos por implementar tu mismo tu web. Danos la oportunidad de trabajar en lo que sabemos mientras tu te dedicas tu pasión a tu negocio."
                            />
                            <DashItem Class="wow fadeInUp" delay="1s"
                                Icon="lnr lnr-layers"
                                Title="Experiencias gráficas y de empaquetado"
                                Details="Aunque diseñamos pensando en lo digital, la experiencia de tu marca debe ser coherente dentro y fuera de línea. Ayudamos a los equipos a crear conceptos y diseños de empaque de productos para dejar una impresión duradera."
                            />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Dashboard;