const WidgetData = {
    PageLink: [
        {
            id: 1,
            name: 'Sobre MACO',
            url: '#',
        },


        {
            id: 5,
            name: 'Contáctanos',
            url: '#',
        },
    ],
    Work: [

        {
            id: 1,
            name: 'Diseño & desarrollo web',
            url: '#'
        },
        {
            id: 2,
            name: 'Branding',
            url: '#'
        },
        {
            id: 3,
            name: 'Hosting, dominio, SEO',
            url: '#'
        },
    ],
    Legal: [
        {
            id: 1,
            name: 'Privacidad',
            url: '#'
        },
        {
            id: 2,
            name: 'Términos y condiciones',
            url: '#'
        },
        {
            id: 3,
            name: 'Política de cookies',
            url: '#'
        },
    ],
    Social: [

        {
            id: 1,
            url: 'https://linkedin.com/',
            icon: 'fa fa-linkedin',
        },
        {
            id: 2,
            url: 'https://www.facebook.com/',
            icon: 'fa fa-facebook',
        },

        {
            id: 3,
            url: 'https://www.instagram.com/',
            icon: 'fa fa-instagram',
        },

    ],
    PageList: [
        {
            id: 1,
            name: 'Política de privacidad',
            url: '#',
        },
        {
            id: 2,
            name: 'MACO',
            url: '#',
        },
        {
            id: 3,
            name: 'FAQ',
            url: '#',
        },

    ],
}
export default WidgetData;