import React, { Component } from "react";
import MainMenu from "../Menu/MainMenu";
import HomeBanner from "../Elements/HomeBanner";
import Works from "../Elements/Works";
import TopFeature from "../Elements/TopFeature";
import Dashboard from "../Elements/Dashboard";
import CustomerSlider from "../Elements/CutomerSlider";
import PriceList from "../Elements/PriceList";
import Subscribe from "../Elements/Subscribe";
import Footer from "../Elements/Footer";
import WidgetData from "../Elements/widgets/WidgetData";

class Home extends Component {
  render() {
    return (
      <div className="body_wrapper">
        <MainMenu Logo="1.png" />
        <HomeBanner
            Details={<React.Fragment>¡Queremos ayudarte  en el mundo digital! <br/>Somos un equipo de profesionales con experiencia en servicios de diseño y desarrollo web para microempresas.</React.Fragment>}
          Image="chart-img.jpg"
          BtnText="¡Empecemos juntos!"
          Url="#Subscribe"
        />
        <Works />
        <TopFeature />
        <Dashboard
          LeftTitle="MACO es para ti si necesitas:"
          RightTitle="MACO te ofrece:"
        />
        <CustomerSlider />
        <PriceList />
        <Subscribe />
        <Footer WidgetData={WidgetData} />
      </div>
    );
  }
}

export default Home;
