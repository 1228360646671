import React, { Component } from "react";
import MainMenu from "../Menu/MainMenu";
import TopFeatureServicios from "../Elements/TopFeatureServicios";
import DashboardServicios from "../Elements/DashboardServicios";
import DashboardTab from "../Elements/DashboardTab";
import CustomerSlider from "../Elements/CutomerSlider";
import PriceList from "../Elements/PriceList";
import Subscribe from "../Elements/Subscribe";
import Footer from "../Elements/Footer";
import WidgetData from "../Elements/widgets/WidgetData";
import RippleBanner from "../Elements/RippleBanner";

class Servicios extends Component {
  render() {
    return (
      <div className="body_wrapper">
        <MainMenu Logo="1.png" />
        <RippleBanner
          Details="Estamos esperando en ayudarte a través de nuestros servicios."
          BtnText="Get Started"
          Url="#"
          Image="2.jpg"
        />
        <TopFeatureServicios />
        <DashboardServicios
          LeftTitle="Identidad Visual"
          RightTitle="Diseño & desarrollo web"
        />
        <DashboardTab />
        <CustomerSlider />
        <PriceList />
        <Subscribe />
        <Footer WidgetData={WidgetData} />
      </div>
    );
  }
}

export default Servicios;
