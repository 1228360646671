import React, {Component} from 'react';
import CenterTitle from './CenterTitle';
const Checkbox=props=>(
    <input type="checkbox"{...props}/>
)
class Subscribe extends Component{
    render(){
        return(
            <section className="subscribe-area" id="Subscribe">
                <div className="container">
                    <CenterTitle Title="¿Necesitas más información? ¡Contáctanos!" Subtitle="Escríbenos, estaremos muy contentos en colaborar contigo"/>
                    <form className="mailchimp" method="post">
                        <div className="subcribe">
                            <input type="text" name="texto" id="reg_email" className="form-control memail" placeholder="Nombre*" />
                        </div>
                        <div className="subcribe">
                            <input type="text" name="texto" id="reg_email" className="form-control memail" placeholder="Mensaje*" />
                        </div>
                        <div className="subcribe">
                            <input type="text" name="texto" id="reg_email" className="form-control memail" placeholder="Móvil*" />
                        </div>

                        <div className="subcribe">
                            <input type="email" name="EMAIL" id="reg_email" className="form-control memail" placeholder="Enter your email*" />
                            <label>
                                <button className="btn sub_btn" type="submit">Enviar</button>
                            </label>
                        </div>
                        <br/>
                        <div className="subcribe">
                            <label>
                                <Checkbox checked={false}/>
                             He leído y acepto <a href={"#"}>la política de privacidad.</a>
                            </label>
                        </div>
                        <br/>
                        <div className="subcribe">
                             <Checkbox checked={false}/>
                            <label>
                               Acepto recibir por email de MACO, pudiendo desistir en cualquier momento.
                            </label>
                        </div>
                    </form>
                </div>
            </section>
        )
    }
}
export default Subscribe;