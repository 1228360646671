import React, {Component} from 'react';
import CenterTitle from './CenterTitle';
import PriceItem from './PriceItem';


class PriceList extends Component{
    render(){
        return(
            <section className="pricing-area" id="price">
                <div className="container">
                    <CenterTitle Title="Planes & precios" Subtitle={<React.Fragment>Hemos dividido nuestros servicios para que puedas elegir lo que necesites, pero si tienes dudas o no ves aquello que necesitas, <a href={"#Subscribe"}>¡contáctanos! </a><br/>  <br/>Los planes que te proponemos proporcionan las herramientas necesarias para la evolución de tu proyecto según la etapa en la que se encuentre. </React.Fragment>}/>
                    <div className="row">
                        <PriceItem Class="wow fadeInLeft" delay="400ms"
                            Icon="lnr lnr-rocket" 
                            Name="Arrancando"
                            list1="Propuesta de identidad visual offline/online"
                            list2="Plan estratégico digital"
                            list3="Elaboración de prototipos digitales"
                                                          />
                        <PriceItem Class="wow fadeInUp" delay="600ms"
                            Icon="lnr lnr-graduation-hat"
                            Name="Avanzado"
                            list1="Identidad visual online"
                            list2="Creación del sitio web"
                            list3="Alquiler de hosting y dominio"
                            list4="Propuesta SEO & mantenimiento"
                        /> 
                        <PriceItem Class="wow fadeInRight" delay="400ms"
                            Icon="lnr lnr-smile"
                            Name="A medida"
                            list1="Identidad visual offline & online"
                            list2="Creación del sitio web"
                            list3="Alquiler de hosting, dominio, email"
                            list4="Revisión, propuesta SEO & mantenimiento web"
                        /> 
                    </div>
                </div>
            </section>
        )
    }
}
export default PriceList;